import { createStore } from 'vuex'

export default createStore({
  state: {
    heroHeader: '',
    articles: [],
    darkMode: false,
    playSound: true,
  },
  getters: {
    headerTheme(state) {
        return state.heroHeader;
    },
    articles(state) {
      return state.articles;
    },
    darkMode(state) {
      return state.darkMode;
    },
    playSound(state) {
      return state.playSound;
    },
  },
  mutations: { // not async
    updateTheme(state, payload) {
      state.heroHeader = payload;
    },
    saveArticle(state, payload) {
      state.articles = payload;
    },
    saveDarkMode(state, payload) {
      state.darkMode = payload;
    },
    saveSoundMode(state, payload) {
      state.playSound = payload;
    },
  },
  actions: { // async
    switchHeader(context, payload) {
      context.commit('updateTheme', payload.value)
    },
    cacheArticles(context, payload) {
      context.commit('saveArticle', payload)
    },
    switchDarkMode(context, payload) {
      context.commit('saveDarkMode', payload)
    },
    switchSoundMode(context, payload) {
      context.commit('saveSoundMode', payload)
    },
  },
  modules: {
  }
})
