<template>
    <section>
        <div class="search" v-if="!skipFirst" >
            <b>Filtrez sur un de mes univers :</b>
            <br>

            <button type="button" class="button" aria-description="Afficher tous les articles" :aria-selected="filterActive == '' ? 'true' : 'false'" :class="filterActive == '' ? 'is-active' : ''" @click="triggerFilter('')">Tout</button>
            <button type="button" class="button" aria-description="Afficher seulement les articles Général" :aria-selected="filterActive == 'général' ? 'true' : 'false'" :class="filterActive == 'général' ? 'is-active' : ''" @click="triggerFilter('général')">Général</button>
            <button type="button" class="button" aria-description="Afficher seulement les articles Tech" :aria-selected="filterActive == 'tech' ? 'true' : 'false'" :class="filterActive == 'tech' ? 'is-active' : ''" @click="triggerFilter('tech')">Tech</button>
            <button type="button" class="button" aria-description="Afficher seulement les articles Work" :aria-selected="filterActive == 'work' ? 'true' : 'false'" :class="filterActive == 'work' ? 'is-active' : ''" @click="triggerFilter('work')">Work</button>
        </div>

        <div v-if="isLoading" class="sm:grid sm:grid-cols-2 md:grid-cols-3">
            <p v-if="error">{{ error }}</p>
            <article-item></article-item>
            <article-item></article-item>
            <article-item></article-item>
            <article-item></article-item>
            <article-item></article-item>
            <article-item></article-item>
        </div>
        <div v-else class="sm:grid sm:grid-cols-2 md:grid-cols-3">
            <article-item
                v-for="article in articlesRendered"
                :key="article.id"
                :id="article.id"
                :title="article.title"
                :slug="article.slug"
                :cover="article.coverUrl"
                :category="article.category"
            ></article-item>
        </div>
    </section>

    <!-- <router-view></router-view> -->
</template>

<style lang="scss" scoped>
section {
    min-height: 70vh;
}

.search {
    height: 100px;
    font-size: 0.8rem;
    text-align: center;

    .button {
        position: relative;
        margin-top: 0.5em;
        margin-right: 0.5em;
        margin-left: 0.5em;
        font-weight: bold;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            opacity: 0;
            background-color: #ef88a8;
            border-radius: 25px;
            background: linear-gradient(
                0deg,
                hsl(291deg 40% 49%) 0%,
                hsl(300deg 38% 49%) 11%,
                hsl(308deg 42% 52%) 22%,
                hsl(315deg 48% 55%) 33%,
                hsl(321deg 53% 58%) 44%,
                hsl(326deg 58% 61%) 56%,
                hsl(330deg 64% 64%) 67%,
                hsl(335deg 69% 67%) 78%,
                hsl(339deg 75% 69%) 89%,
                hsl(343deg 80% 72%) 100%
                );

            transition: opacity 0.8s ease;
        }

        &.is-active {
            color: #FFF;
            border-color: #ef88a8;

            &::before {
                opacity: 1;
            }
        }

        &:focus {
            outline: #ef88a8 solid 2px;
        }
    }
}

// .dark .search .is-active {
//     color: #000;
//     background-color: #FFF;
// }
</style>

<script>
import ArticleItem from './ArticleItem.vue';

export default {
    components: { ArticleItem },
    props: ['skipFirst'],
    data() {
        return {
            apiPath: process.env.VUE_APP_BASE_URL,
            error: null,
            isLoading: true,
            notion: null,
            databaseId: null,
            filters: null,
            articles: [],
            filterActive: '',
        }
    },
    mounted() {
        this.getArticles();
    },
    computed: {
        articlesRendered() {
            if (this.filters) {
                let filteredArticles = this.articles.filter(e => {
                    let categArticle = e.category.name.toLowerCase();
                    return categArticle  === this.filters
                });

                // this.filterActive = res[0].category.name.toLowerCase(); // <- prevent prod build, coz of possibilies side effect
                return filteredArticles;
            }

            // this.filterActive = ''; // <- prevent prod build, coz of possibilies side effect
            return this.articles;
        },
    },
    methods: {
        triggerFilter(filter) {
            this.filterActive = filter;
            this.filters = filter;
        },
        getArticles() {
            let url = process.env.VUE_APP_BASE_URL + 'api/post';
            this.isLoading = true;
            this.error = null,

            fetch(url)
                .then((response) => {
                    if (response.ok) { // .ok is in browser API, as .json()
                        return response.json();
                    }
                })
                .then((data) => {
                    this.isLoading = false;
                    this.articles = data;
                    // this.articlesRendered = data;

                    if (this.skipFirst) {
                        this.articles.shift();
                        // this.articlesRendered.shift();
                    }

                    this.$store.dispatch('cacheArticles', this.articles)
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.error = 'Failed to fetch data : ' + error;
                });
        }
    }
}
</script>