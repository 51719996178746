<template>
    <div class="">
        <footer class="footer" :class="randomFooterDyn">
            <div class="wrap">
                Kevin HENRY<br>
                <b>Pour me contacter :</b>
                <br>
                prenom@hkevin.fr
                <p class="mt-20 text-12"><i>Vous avez tout vu, y a plus qu'à remonter !</i></p>
                <small class="text-10">&copy; 2022 - Inspire toi</small>
             </div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentFooter: null,
        }
    },
    mounted() {
        this.randomFooter();
    },
    methods: {
        random(min, max) {
            return Math.round(Math.random() * (max - min) + min);
        },
        randomFooter() {
            this.currentFooter = 'bg-' + this.random(1, 2);
            return this.currentFooter;
        }
    },
    computed: {
        randomFooterDyn() {
            return this.randomFooter();
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    padding-top: 4em;
    height: 330px;
    text-align: center;
    background-size: 100%;
    background-repeat: no-repeat;

    $mediumBP: 992px;
    // background-position: bottom center;
    @media only screen and (min-width: $mediumBP) and (max-width: 1200px) {
        height: 230px;
    }

    @media only screen and (max-width: $mediumBP) {
        background-position: bottom center;
    }
}

.bg-1 {
    background-image: url('../../assets/wave-1.svg');
}

.bg-2 {
    background-image: url('../../assets/wave-2.svg');
}
</style>