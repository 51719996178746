import { createRouter, createWebHistory } from 'vue-router'
import Store from '../store/index'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'hello',
    component: HomeView,
    meta: {
      header: 'hero',
      title: 'Bonjour, moi c\'est Kevin !',
      description: "Vous appréciez la tech, le code et le design ? Bah j'en parle un peu par ici...",
      metaTags: [
        {
          name: 'description',
          content: 'Développeur créatif passionné de tech !'
        }
      ]
    },
  },
  {
    path: '/articles',
    name: 'articles',
    meta: {
      title: 'Mes articles !',
      description: 'Voilà quelques unes de mes pensées',
      metaTags: [
        {
          name: 'Mes articles',
          content: 'Voilà quelques unes de mes pensées'
        }
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticlesListView.vue')
  },
  {
    path: '/articles/:articleName/:articleId',
    name: 'read',
    meta: {
      title: 'Lecture d\'un article',
      description: "En pleine lecture...",
      metaTags: [
        {
          name: '',
          content: '....'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'À propos de moi',
      description: "Vous êtes curieux ? En voilà un peu plus sur moi...",
      metaTags: [
        {
          name: '',
          content: '....'
        }
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    meta: {
      title: '404 - Page introuvable',
      description: 'Page introuvable',
    },
    component: () => import( '../views/error/PageNotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior (to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth'
          })
        }, 500)
      })
    }

    return { top: 0 };  // Go to the top of the page if no hash
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  document.head.getElementsByTagName('meta')['description'].setAttribute('content', to.meta.description);
  // window.scrollTo(0, 0);

  if (to.name === 'hello') {
    Store.dispatch('switchHeader', {
      value: 'header-hero'
    });
  } else {
    Store.dispatch('switchHeader', {
      value: ''
    });
  }

  next();
});

export default router
