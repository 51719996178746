<template>
    <div>
        <section class="heading text-center mb-20">
            <transition name="fade" mode="out-in">
                <div v-if="show">
                    <h1 class="">Actualités tech, Code, Design &amp; Voyage</h1>
                    <p class="text-20">Développeur créatif freelance, entrepreneur, voyageur.</p>
                </div>
            </transition>

            <transition name="fade" mode="out-in" >
            <div v-if="show">
                <article-item
                    type="hero"
                    :id="article.id"
                    :slug="article.slug"
                    :preview="article.preview"
                    :title="article.title"
                    :cover="article.coverUrl"
                    :category="article.category"
                ></article-item>
            </div>
            </transition>
        </section>

        <transition name="fade" mode="out-in" >
            <div v-if="show">
            <article-list :skipFirst="true"></article-list>
            </div>
        </transition>

        <div class="text-center">
            <router-link to="/articles" class="button">Voir tous les articles</router-link>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import ArticleItem from '@/components/article/ArticleItem.vue'
import ArticleList from '@/components/article/ArticleList.vue'

export default {
  components: { ArticleItem, ArticleList },
  name: 'HomeView',
  data() {
    return {
        isLoading: false,
        error: null,
        show: false,
        article: {},
    }
  },
  mounted() {
      this.getLastArticle();
      this.show = true;
  },
  computed: {
  },
  methods: {
      getLastArticle() {
          this.isLoading = true;
          this.error = null;
          let url = process.env.VUE_APP_BASE_URL + 'api/post/last';

          fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                this.isLoading = false;
                this.article = data;
            })
            .catch((error) => {
                this.isLoading = false;
                this.error = 'Failed to fetch data : ' + error;
            });
      }
  }
}
</script>

<style lang="scss" scoped>
.heading {

    h1,
    p {
        color: #fff;
    }

    h1 {
        width: 80%;
        margin: auto;
        font-size: 3.5rem;
        font-family: abril-fatface, serif;
    }
}

$mediumBP: 992px;
@media only screen and (max-width: $mediumBP) {
    .heading {
        padding: 1em;

        h1 {
            font-size: 2.3rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    .heading h1 {
        width: 90%;
    }
}
</style>