<template>
    <nav id="nav">
        <router-link to="/" tabindex="0">Accueil</router-link>
        <router-link to="/articles" tabindex="0">Le blog</router-link>
        <router-link to="/about" tabindex="0">À propos</router-link>

        <div class="float-right">
            <button @click="toggleLightMode" :aria-label="darkButtonHelpText" :title="darkButtonHelpText" class="light-toggle">
            <span v-if="!isDark"><img src="@/assets/icon/light-on.svg" alt="Allumer la lumière"  width="25"></span>
            <span v-else><img src="@/assets/icon/light-off.svg" alt="Éteindre la lumière" width="20"></span>
            </button>
            <button @click="toggleSoundMode" :aria-label="soundButtonHelpText" :title="soundButtonHelpText" class="sound-toggle">
            <span v-if="!isSound"><img src="@/assets/icon/sound-off.svg" alt="Couper le son"  width="18"></span>
            <span v-else><img src="@/assets/icon/sound-on.svg" alt="Allumer le son" width="35"></span>
            </button>
        </div>
    </nav>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
      soundMode: true,
    }
  },
  created() {
    if (localStorage.getItem('darkMode') === 'true') {
      this.darkMode = true;
      document.body.classList.add('dark');
      this.$store.dispatch('switchDarkMode', this.darkMode);
    }

    if (localStorage.getItem('soundMode') === 'false') {
      this.soundMode = false;
      this.$store.dispatch('switchSoundMode', this.soundMode);
    }
  },
  computed: {
    isDark() {
      return this.darkMode
    },
    isSound() {
      return this.soundMode
    },
    darkButtonHelpText() {
      return this.darkMode ? 'Activer le mode clair' : 'Activer le mode sombre'
    },
    soundButtonHelpText() {
      return this.soundMode ? 'Désactiver les effets sonores' : 'Activer les effets sonores'
    }
  },
  methods: {
      toggleLightMode() {
        this.darkMode = !this.darkMode;
        document.body.classList.toggle('dark');
        localStorage.setItem('darkMode', this.darkMode);
        this.$store.dispatch('switchDarkMode', this.darkMode);
      },
      toggleSoundMode() {
        this.soundMode = !this.soundMode;
        localStorage.setItem('soundMode', this.soundMode);
        this.$store.dispatch('switchSoundMode', this.soundMode);
      },
  },
}
</script>

<style lang="scss" scoped>
$color: #000;

#nav {
  padding: 1.8em;
  position: relative;
  z-index: 20;
}

#nav a {
  padding: 1em;
  color: $color;
  text-decoration: none;
}

#nav a:focus,
#nav a.router-link-exact-active {
  color: #000;
  text-decoration: underline;
}

$mediumBP: 992px;
.float-right {
  position: absolute;
  top: 1.8em;
  right: 1em;
  z-index: 20;
}

@media only screen and (max-width: $mediumBP) {
  #nav {
    padding: 0;
    text-align: left;

    a {
      display: inline-block;
    }
  }

  .float-right {
    float: none;
    position: absolute;
    right: -1em;
    top: 1em;
  }

  .sound-toggle {
    display: none;
  }
}

.light-toggle {
  filter: invert(100);
}

.sound-toggle {
  width: 35px;
}

.dark {
    #nav a:focus,
    #nav a.router-link-exact-active {
    color: #fff;
  }


  .sound-toggle {
    filter: invert(100);
  }
}
</style>