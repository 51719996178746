<template>
  <div :class="switchHeader">
    <the-header></the-header>

    <div class="wrap">
      <main id="main">
          <router-view/>
      </main>
    </div>

    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/layout/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'

export default {
  data() {
    return {}
  },
  components: {
    TheHeader,
    TheFooter,
  },
  computed: {
      switchHeader() {
        return this.$store.getters.headerTheme;
      },
  },
}
</script>

<style lang="scss">
@import "./assets/sass/knacss.scss";

body {
  $duration: 500ms;
  font-family: raleway, Helvetica, Arial, sans-serif;
  font-weight: 300;
  transition: background-color $duration, color $duration;
}


.fade-up-enter-active {
  animation: slide-fade-up 0.5s ease-out;
}

.fade-up--delay-enter-active {
  animation: slide-fade-up 2s;
}

.fade-up--delay-enter-active .sphere {
  transition-delay: 0.25s;
}

.fade-up--long-enter-active {
    animation-duration: 1.5s;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  animation: slide-fade 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-down-enter-active {
  animation: slide-fade-down 0.5s ease-in-out;
}

a:focus {
  // outline: auto;
}


@keyframes slide-fade-up {
  0% {
    transform: translateY(-120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-fade {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-fade-down {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


/* @media (prefers-color-scheme: dark) { } */
.dark {
  background-color: #1f1f47;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  p,
  a {
    color: #fff;
  }

  #nav a {
    color: #fff;
  }
}

.wrap {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

main {
  position: relative;
  z-index: 10;
  // min-height: 60vh;

  a {
    color: #4c0092;
  }
}

.header-hero {
    height: 80vh;
    background: #F17E9F;
    // background: linear-gradient(180deg, #F17E9F 0%, #A14BB0 100%);
    background: linear-gradient(
      0deg,
      hsl(291deg 40% 49%) 0%,
      hsl(300deg 38% 49%) 11%,
      hsl(308deg 42% 52%) 22%,
      hsl(315deg 48% 55%) 33%,
      hsl(321deg 53% 58%) 44%,
      hsl(326deg 58% 61%) 56%,
      hsl(330deg 64% 64%) 67%,
      hsl(335deg 69% 67%) 78%,
      hsl(339deg 75% 69%) 89%,
      hsl(343deg 80% 72%) 100%
    );
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(241, 126, 159, 0) 100%), linear-gradient(180deg, #F17E9F 0%, #A14BB0 100%);
    // background: url('./assets/bubble.png'), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(241, 126, 159, 0) 100%), linear-gradient(180deg, #F17E9F 0%, #A14BB0 100%);
    background-repeat: no-repeat;
    background-position: top right;

    &:after {
      display: block;
      content: " ";
      width: 100%;
      height: 90%;
      position: absolute;
      bottom: -25%;
      background: url('./assets/waves-pack@2x.webp');
      background-size: 100% 90%;
      background-repeat: no-repeat;
    }

    $mediumBP: 992px;
    @media only screen and (min-width: $mediumBP + 1) {
      &:after {
        animation: animSmall 2s ease;
      }
    }


    @media only screen and (max-width: $mediumBP) {
      &:after {
        height: 100%;
        bottom: -44%;
        background-size: 100% 80%;
      }
    }

    @media only screen and (min-width: 1400px) {
      &:after {
        bottom: -20%;
        background: url('./assets/waves-pack@3x.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        animation: animLarge 2s ease;
      }
    }

    #nav a.router-link-active,
    #nav a {
        color: #fff;
    }

    .header {
      margin-bottom: 2.5em;
    }

    .article--hero {
      margin-top: 8em;
    }
}

@keyframes animSmall {
  from {
    bottom: -28%;
  }
  to {
    bottom: -25%;
  }
}


@keyframes animLarge {
  from {
    bottom: -23%;
  }
  to {
    bottom: -20%;
  }
}
</style>
