<template>
    <article class="article" :class="{'article--hero': isType}">
        <header>
            <span v-if="loading" class="article__tag" :class="{'animated-background': loading}"></span>
            <article-category-item v-if="categoryData" :category="categoryData"></article-category-item>
            <img :src="cover" alt="" class="article__illustration" role="presentation" loading="lazy" @load="parallax" ref="cover">
        </header>

        <div class="article__content">
            <h2 class="article__content__title" :class="{'animated-background': loading}">{{ title }}</h2>
            <div class="text-center mt-10">
                <div v-if="loading">
                    <button type="button" disabled="disabled" class="button">Lire</button>
                </div>
                <div v-else>
                    <router-link :to="detailsLink" class="button" tabindex="0">Lire</router-link>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import SimpleParallax from 'simple-parallax-js';
import ArticleCategoryItem from './ArticleCategoryItem.vue';

export default {
    components: {
        ArticleCategoryItem,
    },
    props: ['type', 'id', 'title', 'cover', 'category', 'slug'],
    data() {
        return {
            loading: true,
            isType: false,
        }
    },
    computed: {
        detailsLink() {
            return { name: 'read', params: { articleId: this.id, articleName: this.slug }}
        },
        categoryData() {
            return this.category;
        }
    },
    methods: {
        hasType() {
            if (this.type !== undefined) {
                this.isType = true;
            }

            return this.isType;
        },
        isLoading() {
            if (this.id !== undefined) {
                this.loading = false;
            }

            setTimeout(function() {
                return this.loading;
            }, 1000)

        },
        parallax() {
            if (this.isType) {
                let img = this.$refs.cover;
                new SimpleParallax(img, {
                    customWrapper: 'header',
                    scale: 1.15,
                });
            }
        }
    },
    watch: {
        id() {
            this.isLoading();
        }
    },
    mounted() {
        this.hasType();
        this.isLoading();
    }
}
</script>

<style lang="scss">
$smallBP: 576px;
$mediumBP: 992px;
$shadowColor: rgba(0, 0, 0, 0.15);
$shadowColorDarkMode: rgba(255, 255, 255, 0.05);
$btnColor: #000;
$shadow: 0px 22px 40px 0px;

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.article__illustration,
.animated-background {
    position: relative;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    border-radius: 10px;
}

.button {
    display: inline-block;
    padding: 0.5em 1em 0.4em;
    text-align: center;
    text-decoration: none;
    border: 1px solid $btnColor;
    border-radius: 25px;
    color: #000;
    background-color: rgba(#fff, 0);
    transition: background-color 300ms;

    &:hover {
        text-decoration: none;
        background-color: rgba($btnColor, 0.1);
    }

    &:focus {
        outline: auto;
    }
}

.article {
    position: relative;
    top: 0;
    margin-right: 1.5em;
    margin-bottom: 4em;
    margin-left: 1.5em;
    padding: 1em;
    background: #fff;
    border-radius: 1.25em;
    border: 1px solid #fff;
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.2575);

    // -webkit-box-shadow: $shadow $shadowColor;
    // box-shadow: $shadow $shadowColor;

    box-shadow:
        0px 4.1px 3.6px rgba(0, 0, 0, 0.05),
        0px 11.3px 10px rgba(0, 0, 0, 0.033),
        0px 27.1px 24.1px rgba(0, 0, 0, 0.025),
        0px 90px 80px rgba(0, 0, 0, 0.017)
        ;

    &:hover {
        top: -0.25em;
        box-shadow:
            0px 1.2px 2.2px rgba(0, 0, 0, 0.025),
            0px 2.8px 5.3px rgba(0, 0, 0, 0.036),
            0px 5.3px 10px rgba(0, 0, 0, 0.045),
            0px 9.4px 17.9px rgba(0, 0, 0, 0.054),
            0px 17.5px 33.4px rgba(0, 0, 0, 0.065),
            0px 42px 80px rgba(0, 0, 0, 0.09);

    }

    &.article--hero:hover {
        top: 0!important;
    }

    .button {
        width: 40%;
    }

    &--hero {
        @media only screen and (min-width: $smallBP) {
            display: flex;
            height: 350px;

            > * {
                width: 50%;
            }
        }


        .article__tag {
            left: auto;
            right: 1.7em;
        }

        @media only screen and (min-width: $smallBP) {
            .article__tag {
                right: auto;
                left: 51.5%;
            }
        }

        .article__illustration {
            height: 215px;

            @media only screen and (min-width: $smallBP) and (max-width: $mediumBP) {
                height: 315px;
            }

            @media only screen and (min-width: $mediumBP) {
                height: 315px;
            }
        }

        .article__content {
            padding-left: 1em;
        }

        .article__content__title {
            margin-top: 0.7em;
            height: 220px;
            font-size: 2.8rem;
            -webkit-line-clamp: 3;
        }

        @media only screen and (max-width: $smallBP) {
            .article__content__title {
                font-size: 1.8rem;
                height: auto;
            }
        }
    } //!hero

    &__illustration {
        width: 100%;
        height: 200px;
        border-radius: 0.85em;
        border: none;
        object-fit: cover;
        transition: all 1s;
        transform: translate3d(0px, 0px, 0px) scale(1);
    }

    &__tag {
        position: absolute;
        z-index: 5;
        top: 1.8em;
        right: 1.8em;
        padding: 0.3em;
        border-radius: 7px;
        background-color: #F2F2F2;
    }

    &__content {
        &__title {
            display: -webkit-box;
            margin-top: 0.5em;
            height: 72px;
            color: #000;
            text-align: left;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;

            @media only screen and (max-width: $mediumBP) {
                height: auto;
            }
        }
    }
}

.simpleParallax {
    border-radius: 10px;
    isolation: isolate;
}

.dark {
    $btnColor: #FFF;

    .article {
        background-color: #19183e;
        border: 1px solid #302f52;
        box-shadow:
            0px 4.1px 3.6px rgba(255, 255, 255, 0.05),
            0px 11.3px 10px rgba(255, 255, 255, 0.033),
            0px 27.1px 24.1px rgba(255, 255, 255, 0.025),
            0px 90px 80px rgba(255, 255, 255, 0.017);

        &:hover {
            top: -0.5em;
            box-shadow:
                0px 1.2px 2.2px rgba(255, 255, 255, 0.025),
                0px 2.8px 5.3px rgba(255, 255, 255, 0.036),
                0px 5.3px 10px rgba(255, 255, 255, 0.045),
                0px 9.4px 17.9px rgba(255, 255, 255, 0.054),
                0px 17.5px 33.4px rgba(255, 255, 255, 0.065),
                0px 42px 80px rgba(255, 255, 255, 0.09);
        }

        &__tag {
            color: #000;
        }

        h2,
        h3 {
            color: #fff;
        }
    }

    .button {
        color: #fff;
        border-color: #fff;

        &:hover {
            background-color: rgba(#fff, 0.2);
        }
    }
}
</style>