<template>
    <span class="article__tag" :class="category.color">{{ category.name }}</span>
</template>

<script>
export default {
    props: ['category'],
}
</script>

<style lang="scss" scoped>
span {
    font-size: 0.8rem;
}

span,
.default {
    background-color: #f2f2f2;
}

.green {
    background-color: #D1EAB7;
}

.pink {
    background-color: rgb(255, 202, 202);
}

.orange {
    background-color: rgb(255, 201, 115);
}

.blue {
    background-color: rgb(215, 234, 255);
}
</style>

